<template>
	<header class="header__wrap" :style="{ opacity }">
		<div class="header__wrap-helper">
			<div class="header__side-menu">
				<transition name="fade" mode="in-out">
					<socials v-if="showAddons" class="header__socials" />
				</transition>
			</div>
			<div class="header__wrap-menu">
				<nav class="header__menu">
					<router-link
						v-for="link in menu"
						:key="link.id"
						:to="{ path: link.url }"
						active-class="active"
					>
						{{ $t(`menu.${link.text}`) }}
					</router-link>
					<div class="header__menu-sub">
						<router-link
							v-for="link in subMenu"
							:key="link.id"
							:to="{ path: link.url }"
							active-class="active"
						>
							{{ $t(`menu.${link.text}`) }}
						</router-link>
					</div>
				</nav>
			</div>
			<img :src="$imgUrl('LOGO.svg')" @click="goHome" :style="logoStyle" />
			<div class="header__side-menu">
				<transition name="fade" mode="in-out">
					<lang-switch v-if="showAddons" class="header__flags" />
				</transition>
				<transition name="fade" mode="in-out">
					<lang-switch
						v-if="showAddons"
						class="header__flags header__side-menu__sub"
					/>
				</transition>
			</div>
			<nav class="header__mobile">
				<router-link
					v-for="link in [...menu, ...subMenu]"
					:key="link.id"
					:to="{ path: link.url }"
					active-class="active"
				>
					{{ $t(`menu.${link.text}`) }}
				</router-link>
			</nav>
			<div class="header__wrap-helper-background" />
		</div>
	</header>
</template>

<script>
import Socials from '@/components/socials'
import LangSwitch from './lang-switch'

export default {
	components: {
		LangSwitch,
		Socials
	},
	data: () => ({
		lastScrollTop: 0,
		scrolled: 0,
		opacity: 1,
		logoStyle: {
			height: '4rem'
		},
		width: 1000,
		showAddons: true
	}),
	computed: {
		menu: ({ $store }) => $store.getters['settings/menu'],
		subMenu: ({ $store }) => $store.getters['settings/subMenu']
	},
	mounted() {
		window.addEventListener('scroll', this.scrollFunc)
		window.addEventListener('resize', this.scrollFunc)
		this.scrollFunc()
	},
	methods: {
		goHome() {
			this.$router.history.push({ path: '/' })
		},
		scrollFunc() {
			this.width = window.innerWidth

			this.scrolled = Math.max(
				window.pageYOffset,
				document.documentElement.scrollTop,
				document.body.scrollTop
			)

			if (this.scrolled >= 50) {
				if (this.scrolled > this.lastScrollTop) {
					this.opacity = 0
					this.$emit('change-opacity', 0)

					this.logoStyle = {
						height: this.width >= 900 ? '4rem' : '4rem'
					}
				} else {
					this.opacity = 1
					this.$emit('change-opacity', 1)

					this.logoStyle = {
						height: this.width >= 900 ? '4rem' : '4rem'
					}
				}
			} else {
				this.opacity = 1
				this.$emit('change-opacity', 1)

				this.logoStyle = {
					height: this.width >= 900 ? '4rem' : '7rem'
				}
			}

			this.showAddons = this.width >= 900 ? true : this.logoStyle.height === '7rem'

			this.lastScrollTop = this.scrolled <= 0 ? 0 : this.scrolled
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollFunc)
		window.removeEventListener('resize', this.scrollFunc)
	}
}
</script>
