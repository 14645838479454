export default {
	imgUrl(pic) {
		return require('@/assets/img/' + pic)
	},
	compareValues(key, order = 'asc') {
		return function innerSort(a, b) {
			if (
				!Object.prototype.hasOwnProperty.call(a, key) ||
				!Object.prototype.hasOwnProperty.call(b, key)
			) {
				// property doesn't exist on either object
				return 0
			}

			const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
			const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

			let comparison = 0
			if (varA > varB) {
				comparison = 1
			} else if (varA < varB) {
				comparison = -1
			}
			return order === 'desc' ? comparison * -1 : comparison
		}
	},
	async asyncForEach(array, callback) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array)
		}
	},
	shuffle(a) {
		for (let i = a.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1))
			;[a[i], a[j]] = [a[j], a[i]]
		}
		return a
	},
	randomInt(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min)
	},
	removeOrphans: (str) => {
		const polishOrphans = [
			'a',
			'i',
			'o',
			'u',
			'w',
			'z',
			'A',
			'I',
			'O',
			'U',
			'W',
			'Z'
		]
		const englishOrphans = [
			'of',
			'at',
			'as',
			'it',
			'an',
			'to',
			'in',
			'In',
			'on',
			'be',
			'is',
			'by'
		]

		const lettersToReplace = [...polishOrphans, ...englishOrphans]

		const textReplaced = str.split(' ').map((i) => {
			if (lettersToReplace.includes(i)) {
				return i + '\xa0'
			}
			return i + ' '
		})
		return textReplaced.join('')
	}
}
