<template>
	<div class="static__copy">
		<h2 class="heading__h2">{{ $t(`${item.type}.${item.name}.title`) }}</h2>
		<ul class="static__work">
			<li v-for="(i, index) in item.work" :key="index">
				{{ $t(`${item.type}.${item.name}.work.${i}`) }}
			</li>
		</ul>
		<ul v-if="item.additional" class="static__additional">
			<li v-for="(i, index) in item.additional" :key="index">
				<h4 class="heading__h4">
					{{ $t(`${item.type}.${item.name}.additional.${i}.title`) }}
				</h4>
				<a :href="$t(`${item.type}.${item.name}.additional.${i}.link`)">
					{{ $t(`${item.type}.${item.name}.additional.${i}.text`) }}
				</a>
			</li>
		</ul>
		<template v-if="item.texts">
			<p v-for="(i, index) in item.texts" :key="index" class="static__text">
				{{ $t(`${item.type}.${item.name}.text.${i}`) }}
			</p>
		</template>
		<p v-else class="static__text">
			{{ $t(`${item.type}.${item.name}.text`) }}
		</p>
		<ul class="static__tags">
			<li v-for="(tag, index) in item.tags" :key="index">
				{{ tag }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			required: true
		}
	}
}
</script>
