<template>
	<footer class="footer__wrap">
		<socials />
	</footer>
</template>

<script>
import Socials from '@/components/socials'
export default {
	components: { Socials }
}
</script>
