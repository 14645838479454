import { get } from 'lodash'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import { routes } from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
})

router.beforeEach(async (to, from, next) => {
	try {
		if (get(to, 'meta.description')) {
			store.dispatch('settings/toggleDescription', get(to, 'meta.description'))
		} else {
			store.dispatch('settings/toggleDescription', 0)
		}
		next()
	} catch (err) {
		next('/404')
	}
})

export default router
