<template>
	<div class="u__position-relative" ref="container">
		<hooper :settings="hooperSettings" class="static__hooper" ref="hooper">
			<slide v-for="(item, index) in items" :key="index" :class="slideClass">
				<div
					class="static__slide-image"
					@mouseover="slideHover(index)"
					@click="tinyIndex = index"
				>
					<img :src="$imgUrl(item)" alt="item" />
				</div>
			</slide>
		</hooper>
		<vue-slider
			v-bind="sliderSettings"
			v-model="currentSlide"
			@change="changeSlide"
			class="static__slider"
		/>
		<Tinybox v-model="tinyIndex" :images="tinyImages" no-thumbs />
	</div>
</template>

<script>
import { Hooper, Slide } from 'hooper'
import VueSlider from 'vue-slider-component'
import Tinybox from 'vue-tinybox'
import { map } from 'lodash'

export default {
	props: {
		items: {
			type: Array,
			required: true
		},
		slideClass: {
			type: String,
			required: true
		}
	},
	components: {
		Hooper,
		Slide,
		VueSlider,
		Tinybox
	},
	data: () => ({
		hooperSettings: {
			centerMode: false,
			infiniteScroll: false,
			wheelControl: false,
			autoPlay: false,
			hoverPause: false,
			trimWhiteSpace: true,
			initialSlide: 0,
			keysControl: false,
			touchDrag: false,
			mouseDrag: false
		},
		sliderSettings: {
			dotSize: 20,
			width: '25rem',
			height: 2,
			contained: false,
			direction: 'ltr',
			min: 1,
			interval: 1,
			disabled: false,
			clickable: true,
			duration: 0.5,
			tooltip: 'active',
			tooltipPlacement: 'top',
			useKeyboard: false,
			keydownHook: null,
			dragOnClick: false,
			enableCross: true,
			fixed: false,
			order: true,
			marks: false,
			process: true
		},
		currentSlide: 1,
		slideNodes: [],
		tinyIndex: null,
		tinyImages: []
	}),
	mounted() {
		this.sliderSettings.max = this.items.length
		this.calculateImages()

		this.slideNodes[0].firstChild.classList.remove('hidden')
		this.populatePopupImages()

		window.addEventListener('resize', this.calculateImages)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calculateImages)
	},
	methods: {
		populatePopupImages() {
			this.tinyImages = map(this.items, (item) => ({
				src: this.$imgUrl(item)
			}))
		},
		slideHover(index) {
			this.currentSlide = index + 1
			this.changeSlide()
		},
		calculateImages() {
			this.slideNodes = document.querySelectorAll(`.${this.slideClass}`)

			const containerWidth = this.$refs.container.getBoundingClientRect().width
			const totalWidth =
				this.slideNodes[0].getBoundingClientRect().width * this.slideNodes.length -
				1
			const magicNumber =
				this.slideNodes[0].getBoundingClientRect().width -
				(totalWidth - containerWidth) / (this.slideNodes.length - 1)

			let j = 0
			for (let i = 0; i < this.slideNodes.length; i++) {
				this.slideNodes[j].style.top = `${i}rem`
				this.slideNodes[j].style.right = `${i * magicNumber}px`
				this.slideNodes[j].style.zIndex = `-${i}`
				this.slideNodes[j].firstChild.classList.add('hidden')
				j++
			}
			this.slideNodes[0].firstChild.classList.remove('hidden')
			this.slideNodes[0].style.zIndex = 1
		},
		changeSlide() {
			this.slideNodes = document.querySelectorAll(`.${this.slideClass}`)
			const newVal = this.currentSlide - 1
			this.$refs.hooper.slideTo(newVal)

			this.slideNodes.forEach((slide, i) => {
				slide.style.zIndex = `-${i}`
				slide.firstChild.classList.add('hidden')
			})
			this.slideNodes[newVal].style.zIndex = 1
			this.slideNodes[newVal].firstChild.classList.remove('hidden')
		}
	}
}
</script>
