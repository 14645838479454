export default {
	namespaced: true,
	state: {
		menu: [
			{
				id: 0,
				text: 'motion',
				url: '/motion'
			},
			{
				id: 1,
				text: 'web',
				url: '/web'
			},
			{
				id: 2,
				text: 'static',
				url: '/static'
			},
			{
				id: 3,
				text: 'photo',
				url: '/photo'
			}
		],
		subMenu: [
			{
				id: 4,
				text: 'contact',
				url: '/contact'
			}
		],
		areas: [],
		currentArea: null,
		pageDescription: 0
	},
	getters: {
		menu: (state) => state.menu,
		subMenu: (state) => state.subMenu,
		areas: (state) => state.areas,
		currentArea: (state) => state.currentArea,
		pageDescription: (state) => state.pageDescription
	},
	mutations: {
		updateAreas(state, data) {
			state.areas = data
		},
		updateCurrentArea(state, data) {
			state.currentArea = data
		},
		clearArea(state) {
			state.areas = []
			state.currentArea = null
		},
		toggleDescription(state, number) {
			state.pageDescription = number
		}
	},
	actions: {
		updateAreas: ({ commit }, data) => commit('updateAreas', data),
		updateCurrentArea: ({ commit }, data) => commit('updateCurrentArea', data),
		clearArea: ({ commit }) => commit('clearArea'),
		toggleDescription: ({ commit }, number) => commit('toggleDescription', number)
	}
}
