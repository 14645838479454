export const STATIC = 'static'
export const WEB = 'web'
export const MOTION = 'motion'
export const PHOTO = 'photo'

export const ID = '#ID'
export const SOCIAL_MEDIA = '#SOCIALMEDIA'
export const DTP = '#DTP'
export const PRODUCT = '#PRODUCT'
export const ILLUSTRATION = '#ILLUSTRATION'
export const INFOGRAPHIC = '#INFOGRAPHIC'
export const MUSIC_VIDEO = '#MUSICVIDEO'
export const PHOTO_PRODUCT = '#PHOTOPRODUCT'
export const PACKAGE = '#PACKAGE'
export const COVER = '#COVER'
export const EXPLAINER = '#EXPLAINER'
export const COMMUNICATION = '#COMMUNICATION'
export const WEB_TAG = '#WEB'
export const MOTION_TAG = '#MOTION'
export const POSTER = '#POSTER'
export const MERCHE = '#MERCHE'
