import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import LottieAnimation from 'lottie-vuejs'

import { i18n } from './plugins/i18n'

import './plugins/settings'

Vue.config.productionTip = false

Vue.use(LottieAnimation)

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App)
}).$mount('#app')
