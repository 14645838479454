import {
	STATIC,
	WEB,
	MOTION,
	PHOTO,
	ID,
	SOCIAL_MEDIA,
	DTP,
	PRODUCT,
	ILLUSTRATION,
	INFOGRAPHIC,
	MUSIC_VIDEO,
	PHOTO_PRODUCT,
	PACKAGE,
	COVER,
	EXPLAINER,
	COMMUNICATION,
	WEB_TAG,
	MOTION_TAG,
	POSTER,
	MERCHE
} from '@/helpers/constants'

export default [
	{
		id: 0,
		type: STATIC,
		name: 'thekrag',
		tags: [ID, SOCIAL_MEDIA, DTP, PRODUCT],
		area: [STATIC],
		images: [
			'THEKRAG/THEKRAG-static-00.jpg',
			'THEKRAG/THEKRAG-static-01.jpg',
			'THEKRAG/THEKRAG-static-02.jpg',
			'THEKRAG/THEKRAG-static-03.jpg',
			'THEKRAG/THEKRAG-static-04.jpg',
			'THEKRAG/THEKRAG-static-05.jpg',
			'THEKRAG/THEKRAG-static-06.jpg',
			'THEKRAG/THEKRAG-static-07.jpg',
			'THEKRAG/THEKRAG-static-08.jpg',
			'THEKRAG/THEKRAG-static-09.jpg',
			'THEKRAG/THEKRAG-static-10.jpg',
			'THEKRAG/THEKRAG-static-11.jpg',
			'THEKRAG/THEKRAG-static-12.jpg',
			'THEKRAG/THEKRAG-static-13.jpg',
			'THEKRAG/THEKRAG-static-14.jpg',
			'THEKRAG/THEKRAG-static-15.jpg',
			'THEKRAG/THEKRAG-static-16.jpg',
			'THEKRAG/THEKRAG-static-17.jpg'
		],
		year: 2015,
		work: [0, 1, 2, 3],
		additional: [0]
	},
	{
		id: 1,
		type: STATIC,
		name: 'szreniawa',
		tags: [ID, INFOGRAPHIC, ILLUSTRATION],
		area: [STATIC],
		images: [
			'SZRENIAWA/1.jpg',
			'SZRENIAWA/2.jpg',
			'SZRENIAWA/3.jpg',
			'SZRENIAWA/4.jpg',
			'SZRENIAWA/5.jpg',
			'SZRENIAWA/6.jpg',
			'SZRENIAWA/7.jpg',
			'SZRENIAWA/8.jpg',
			'SZRENIAWA/9.jpg'
		],
		work: [0, 1, 2, 3],
		additional: [0]
	},
	{
		id: 27,
		type: STATIC,
		name: 'vanessa',
		tags: [ID, COMMUNICATION, SOCIAL_MEDIA],
		area: [STATIC],
		images: [
			'VANESSA/01-V.jpg',
			'VANESSA/02-V.jpg',
			'VANESSA/03-V.jpg',
			'VANESSA/04-V.jpg',
			'VANESSA/05-V.jpg',
			'VANESSA/06-V.png',
			'VANESSA/07-V.jpg',
			'VANESSA/08-V.jpg',
			'VANESSA/09-V.png'
		],
		year: 2021,
		work: [0, 1],
		additional: [0]
	},
	{
		id: 2,
		type: STATIC,
		name: 'smok',
		tags: [ILLUSTRATION, MUSIC_VIDEO],
		area: [STATIC, MOTION],
		images: [
			'SMOK/SMOK-01.jpg',
			'SMOK/SMOK-02.jpg',
			'SMOK/SMOK-03.jpg',
			'SMOK/SMOK-04.jpg',
			'SMOK/SMOK-05.jpg',
			'SMOK/SMOK-06.jpg',
			'SMOK/SMOK-07.jpg'
		],
		year: 2020,
		work: [0, 1, 2],
		additional: [0]
	},
	{
		id: 3,
		type: STATIC,
		name: 'safebox',
		tags: [DTP, PHOTO_PRODUCT, PACKAGE],
		area: [STATIC],
		images: [
			'SAFEBOX/1.jpg',
			'SAFEBOX/2.jpg',
			'SAFEBOX/3.jpg',
			'SAFEBOX/4.jpg',
			'SAFEBOX/5.jpg',
			'SAFEBOX/6.jpg'
		],
		year: 2020,
		work: [0, 1, 2],
		additional: [0]
	},
	{
		id: 4,
		type: STATIC,
		name: 'mietha',
		tags: [DTP, COVER],
		area: [STATIC],
		images: [
			'MIETHA/1.jpg',
			'MIETHA/2.jpg',
			'MIETHA/3.jpg',
			'MIETHA/4.jpg',
			'MIETHA/5.jpg',
			'MIETHA/6.jpg',
			'MIETHA/7.jpg',
			'MIETHA/8.jpg',
			'MIETHA/9.jpg',
			'MIETHA/10.jpg',
			'MIETHA/11.jpg',
			'MIETHA/12.jpg'
		],
		year: 2019,
		work: [0, 1, 2],
		additional: [0, 1]
	},
	{
		id: 5,
		type: STATIC,
		name: 'masterklasa',
		tags: [COMMUNICATION, WEB_TAG, MOTION_TAG],
		area: [STATIC, WEB],
		images: [
			'MASTERKLASA/1.jpg',
			'MASTERKLASA/2.jpg',
			'MASTERKLASA/3.jpg',
			'MASTERKLASA/4.jpg',
			'MASTERKLASA/5.jpg',
			'MASTERKLASA/6.jpg',
			'MASTERKLASA/7.gif',
			'MASTERKLASA/8.jpg',
			'MASTERKLASA/9.gif',
			'MASTERKLASA/10.gif',
			'MASTERKLASA/11.jpg'
		],
		year: 2020,
		work: [0, 1, 2],
		additional: [0]
	},
	{
		id: 6,
		type: STATIC,
		name: 'corporate-explainer',
		tags: [ILLUSTRATION, EXPLAINER],
		area: [STATIC, MOTION],
		images: [
			'CORPORATEEXPLAINER/1.jpg',
			'CORPORATEEXPLAINER/2.jpg',
			'CORPORATEEXPLAINER/3.jpg',
			'CORPORATEEXPLAINER/4.jpg',
			'CORPORATEEXPLAINER/5.jpg',
			'CORPORATEEXPLAINER/6.jpg',
			'CORPORATEEXPLAINER/7.jpg',
			'CORPORATEEXPLAINER/8.jpg',
			'CORPORATEEXPLAINER/9.jpg',
			'CORPORATEEXPLAINER/10.jpg',
			'CORPORATEEXPLAINER/11.jpg'
		],
		work: [0, 1, 2],
		additional: [0]
	},
	{
		id: 7,
		type: STATIC,
		name: 'jesiennapietrze',
		tags: [COMMUNICATION, POSTER, DTP],
		area: [STATIC],
		images: [
			'JESIENNAPIETRZE/1.jpg',
			'JESIENNAPIETRZE/2.jpg',
			'JESIENNAPIETRZE/3.jpg',
			'JESIENNAPIETRZE/4.jpg',
			'JESIENNAPIETRZE/5.jpg'
		],
		work: [0, 1, 2, 3],
		additional: [0, 1]
	},
	{
		id: 8,
		type: STATIC,
		name: 'endy',
		tags: [COVER, DTP],
		area: [STATIC],
		images: [
			'ENDY/1.jpg',
			'ENDY/2.jpg',
			'ENDY/3.jpg',
			'ENDY/4.jpg',
			'ENDY/5.jpg',
			'ENDY/6.jpg',
			'ENDY/7.jpg'
		],
		year: 2018,
		work: [0, 1],
		additional: [0, 1, 2, 3]
	},
	{
		id: 9,
		type: STATIC,
		name: 'da',
		tags: [ID, SOCIAL_MEDIA, MERCHE],
		area: [STATIC],
		images: [
			'DA/1.jpg',
			'DA/2.jpg',
			'DA/3.jpg',
			'DA/4.jpg',
			'DA/5.jpg',
			'DA/6.jpg',
			'DA/7.jpg',
			'DA/8.jpg',
			'DA/9.jpg',
			'DA/10.jpg',
			'DA/11.jpg',
			'DA/12.jpg'
		],
		work: [0, 1, 2],
		additional: [0],
		texts: [0, 1]
	},
	{
		id: 10,
		type: STATIC,
		name: 'bokka',
		tags: [ILLUSTRATION, POSTER, SOCIAL_MEDIA],
		area: [STATIC],
		images: ['BOKKA/1.jpg', 'BOKKA/2.jpg', 'BOKKA/3.jpg'],
		year: 2020,
		work: [0, 1, 2],
		additional: [0, 1]
	},
	{
		id: 28,
		type: STATIC,
		name: 'eq',
		tags: [ID, WEB_TAG],
		area: [STATIC],
		images: [
			'EQ/EQ-proj-01.jpg',
			'EQ/EQ-proj-02.jpg',
			'EQ/EQ-proj-03.jpg',
			'EQ/EQ-proj-04.jpg',
			'EQ/EQ-proj-05.jpg',
			'EQ/EQ-proj-06.jpg'
		],
		year: 2020,
		work: [0, 1],
		additional: [0]
	},
	{
		id: 11,
		type: STATIC,
		name: 'bibobit-osiecka',
		tags: [COMMUNICATION, COVER],
		area: [STATIC, MOTION],
		images: ['BIBOBIT-OSIECKA/01-bibobit.jpg', 'BIBOBIT-OSIECKA/02-bibobit.jpg'],
		year: 2020,
		work: [0, 1, 2, 3],
		additional: [0, 1]
	},
	{
		id: 12,
		type: WEB,
		name: 'michalgruszczynski',
		area: [WEB],
		images: {
			desktop: [
				'MICHALGRUSZCZYNSKI/desktop/01-a_0004_01-a.jpg',
				'MICHALGRUSZCZYNSKI/desktop/01-a_0003_02-a.jpg',
				'MICHALGRUSZCZYNSKI/desktop/01-a_0002_03-a.jpg',
				'MICHALGRUSZCZYNSKI/desktop/01-a_0001_04-a.jpg',
				'MICHALGRUSZCZYNSKI/desktop/01-a_0000_05-a.jpg'
			],
			mobile: [
				'MICHALGRUSZCZYNSKI/mobile/01-b_0004_01-b.jpg',
				'MICHALGRUSZCZYNSKI/mobile/01-b_0003_02-b.jpg',
				'MICHALGRUSZCZYNSKI/mobile/01-b_0002_03-b.jpg',
				'MICHALGRUSZCZYNSKI/mobile/01-b_0001_04-b.jpg',
				'MICHALGRUSZCZYNSKI/mobile/01-b_0000_05-b.jpg'
			]
		}
	},
	{
		id: 13,
		type: WEB,
		name: 'jakubhajduk',
		area: [WEB],
		images: {
			desktop: [
				'JAKUBHAJDUK/desktop/01-a_0006_01-a.jpg',
				'JAKUBHAJDUK/desktop/01-a_0005_02-a.jpg',
				'JAKUBHAJDUK/desktop/01-a_0004_03-a.jpg',
				'JAKUBHAJDUK/desktop/01-a_0003_04-a.jpg',
				'JAKUBHAJDUK/desktop/01-a_0002_05-a.jpg',
				'JAKUBHAJDUK/desktop/01-a_0001_06-a.jpg',
				'JAKUBHAJDUK/desktop/01-a_0000_07-a.jpg'
			],
			mobile: [
				'JAKUBHAJDUK/mobile/01-b_0006_01-b.jpg',
				'JAKUBHAJDUK/mobile/01-b_0005_02-b.jpg',
				'JAKUBHAJDUK/mobile/01-b_0004_03-b.jpg',
				'JAKUBHAJDUK/mobile/01-b_0003_04-b.jpg',
				'JAKUBHAJDUK/mobile/01-b_0002_05-b.jpg',
				'JAKUBHAJDUK/mobile/01-b_0001_06-b.jpg',
				'JAKUBHAJDUK/mobile/01-b_0000_07-b.jpg'
			]
		}
	},
	{
		id: 14,
		type: WEB,
		name: 'jakubzawadzki',
		area: [WEB],
		images: {
			desktop: [
				'JAKUBZAWADZKI/desktop/01-a_0003_01-a.jpg',
				'JAKUBZAWADZKI/desktop/01-a_0002_02-a.jpg',
				'JAKUBZAWADZKI/desktop/01-a_0001_03-a.jpg',
				'JAKUBZAWADZKI/desktop/01-a_0000_04-a.jpg'
			],
			mobile: [
				'JAKUBZAWADZKI/mobile/01-b_0003_01-b.jpg',
				'JAKUBZAWADZKI/mobile/01-b_0002_02-b.jpg',
				'JAKUBZAWADZKI/mobile/01-b_0001_03-b.jpg',
				'JAKUBZAWADZKI/mobile/01-b_0000_04-b.jpg'
			]
		}
	},
	{
		id: 15,
		type: WEB,
		name: 'landingpages',
		area: [WEB],
		images: {
			desktop: [
				'LANDINGPAGES/desktop/01-a_0001_01-a.jpg',
				'LANDINGPAGES/desktop/01-a_0000_02-a.jpg'
			],
			mobile: [
				'LANDINGPAGES/mobile/01-b_0001_01-b.jpg',
				'LANDINGPAGES/mobile/01-b_0000_02-b.jpg'
			]
		}
	},
	{
		id: 16,
		type: WEB,
		name: 'masterklasa',
		area: [STATIC, WEB],
		images: {
			desktop: [
				'MASTERKLASA/desktop/01-a_0003_01.jpg',
				'MASTERKLASA/desktop/01-a_0002_02.jpg',
				'MASTERKLASA/desktop/01-a_0001_03.jpg',
				'MASTERKLASA/desktop/01-a_0000_04.jpg'
			],
			mobile: [
				'MASTERKLASA/mobile/01-b_0003_01.jpg',
				'MASTERKLASA/mobile/01-b_0002_02.jpg',
				'MASTERKLASA/mobile/01-b_0001_03.jpg',
				'MASTERKLASA/mobile/01-b_0000_04.jpg'
			]
		}
	},
	{
		id: 17,
		type: MOTION,
		name: 'smok',
		area: [STATIC, MOTION],
		images: [
			'SMOK/CUD-motion-smok-01.mp4',
			'SMOK/CUD-motion-smok-02.mp4',
			'SMOK/CUD-motion-smok-03.mp4',
			'SMOK/CUD-motion-smok-04.mp4',
			'SMOK/CUD-motion-smok-05.mp4',
			'SMOK/CUD-motion-smok-06.mp4',
			'SMOK/CUD-motion-smok-07.mp4',
			'SMOK/CUD-motion-smok-08.mp4'
		]
	},
	{
		id: 18,
		type: MOTION,
		name: 'explainers-elements',
		area: [MOTION],
		images: [
			'EXPLAINERSELEMENTS/CUD-motion-corporate-elements-01.mp4',
			'EXPLAINERSELEMENTS/CUD-motion-corporate-elements-02.mp4',
			'EXPLAINERSELEMENTS/CUD-motion-corporate-elements-03.mp4',
			'EXPLAINERSELEMENTS/CUD-motion-corporate-elements-04.mp4',
			'EXPLAINERSELEMENTS/CUD-motion-corporate-elements-05.mp4',
			'EXPLAINERSELEMENTS/CUD-motion-corporate-elements-06.mp4',
			'EXPLAINERSELEMENTS/CUD-motion-corporate-elements-07.mp4',
			'EXPLAINERSELEMENTS/CUD-motion-corporate-elements-08.mp4',
			'EXPLAINERSELEMENTS/CUD-motion-corporate-elements-09.mp4'
		]
	},
	{
		id: 19,
		type: MOTION,
		name: 'cosmos-elements',
		area: [MOTION],
		images: [
			'COSMOSELEMENTS/CUD-motion-cosmos-00001.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00002.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00003.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00004.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00005.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00006.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00007.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00008.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00009.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00010.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00011.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00012.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00013.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00014.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00015.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00016.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00017.mp4',
			'COSMOSELEMENTS/CUD-motion-cosmos-00018.mp4'
		],
		includeLink: true
	},
	{
		id: 20,
		type: MOTION,
		name: 'corporate-explainer',
		area: [STATIC, MOTION],
		images: [
			'CORPORATEEXPLAINER/CUD-motion-lidl-01.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-02.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-03.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-04.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-05.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-06.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-07.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-b-01.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-b-02.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-b-03.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-b-04.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-b-05.mp4',
			'CORPORATEEXPLAINER/CUD-motion-lidl-b-06.mp4'
		]
	},
	{
		id: 21,
		type: MOTION,
		name: 'kropla',
		area: [MOTION],
		images: [
			'KROPLA/CUD-motion-BIBOBIT-KROPLA-01.mp4',
			'KROPLA/CUD-motion-BIBOBIT-KROPLA-02.mp4',
			'KROPLA/CUD-motion-BIBOBIT-KROPLA-03.mp4',
			'KROPLA/CUD-motion-BIBOBIT-KROPLA-04.mp4',
			'KROPLA/CUD-motion-BIBOBIT-KROPLA-05.mp4',
			'KROPLA/CUD-motion-BIBOBIT-KROPLA-06.mp4',
			'KROPLA/CUD-motion-BIBOBIT-KROPLA-07.mp4',
			'KROPLA/CUD-motion-BIBOBIT-KROPLA-08.mp4',
			'KROPLA/CUD-motion-BIBOBIT-KROPLA-09.mp4',
			'KROPLA/CUD-motion-BIBOBIT-KROPLA-10.mp4'
		]
	},
	{
		id: 25,
		type: MOTION,
		name: 'tlove',
		area: [MOTION],
		images: [
			'TLOVE/TLOVE-king-01.mp4',
			'TLOVE/TLOVE-king-02.mp4',
			'TLOVE/TLOVE-king-03.mp4',
			'TLOVE/TLOVE-king-04.mp4',
			'TLOVE/TLOVE-king-05.mp4',
			'TLOVE/TLOVE-king-06.mp4',
			'TLOVE/TLOVE-king-07.mp4',
			'TLOVE/TLOVE-king-08.mp4',
			'TLOVE/TLOVE-king-09.mp4'
		]
	},
	{
		id: 26,
		type: MOTION,
		name: 'bibobit-osiecka',
		area: [MOTION],
		images: [
			'BIBOBIT-OSIECKA/BIBOBIT-OSIECKA-01.mp4',
			'BIBOBIT-OSIECKA/BIBOBIT-OSIECKA-02.mp4',
			'BIBOBIT-OSIECKA/BIBOBIT-OSIECKA-03.mp4',
			'BIBOBIT-OSIECKA/BIBOBIT-OSIECKA-04.mp4',
			'BIBOBIT-OSIECKA/BIBOBIT-OSIECKA-05.mp4',
			'BIBOBIT-OSIECKA/BIBOBIT-OSIECKA-06.mp4'
		]
	},
	{
		id: 22,
		type: PHOTO,
		name: 'vanessa',
		area: [PHOTO],
		images: [
			'VANESSA/VANESSA_01.jpg',
			'VANESSA/VANESSA_02.jpg',
			'VANESSA/VANESSA_03.jpg',
			'VANESSA/VANESSA_04.jpg',
			'VANESSA/VANESSA_05.jpg',
			'VANESSA/VANESSA_06.jpg',
			'VANESSA/VANESSA_07.jpg',
			'VANESSA/VANESSA_08.jpg',
			'VANESSA/VANESSA_09.jpg',
			'VANESSA/VANESSA_10.jpg',
			'VANESSA/VANESSA_11.jpg',
			'VANESSA/VANESSA_12.jpg',
			'VANESSA/VANESSA_13.jpg'
		]
	},
	{
		id: 23,
		type: PHOTO,
		name: 'aromaking',
		area: [PHOTO],
		images: [
			'AROMAKING/01.jpg',
			'AROMAKING/02.jpg',
			'AROMAKING/03.jpg',
			'AROMAKING/04.jpg',
			'AROMAKING/05.jpg',
			'AROMAKING/06.jpg'
		]
	}
]
