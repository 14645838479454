<template>
	<article class="article motion-article">
		<div class="u__position-relative" ref="container">
			<hooper :settings="hooperSettings" class="motion__hooper" ref="hooper">
				<slide v-for="asset in item.images" :key="asset" class="motion__slide">
					<div class="motion__slide-image">
						<video preload="true" autoplay muted loop playsinline class="video">
							<source :src="$imgUrl(asset)" type="video/mp4" />
							Your browser does not support HTML5 video.
						</video>
					</div>
				</slide>
			</hooper>
			<vue-slider
				v-if="isReady"
				v-bind="sliderSettings"
				v-model="currentSlide"
				@change="changeSlide"
				class="motion__slider"
			/>
			<div class="motion__slide-text">
				<h2 class="heading__h2">{{ $t(`${item.type}.${item.name}.title`) }}</h2>
				<p>{{ $t(`${item.type}.${item.name}.text`) }}</p>
				<a v-if="item.includeLink" :href="`mailto:${$t('external.email')}`">
					{{ $t(`${item.type}.${item.name}.link`) }}
				</a>
			</div>
		</div>
	</article>
</template>

<script>
import { Hooper, Slide } from 'hooper'
import VueSlider from 'vue-slider-component'

export default {
	components: {
		Hooper,
		Slide,
		VueSlider
	},
	data: ({ $route }) => ({
		slug: $route.params.motion,
		item: {},
		hooperSettings: {
			centerMode: false,
			infiniteScroll: false,
			wheelControl: false,
			autoPlay: false,
			hoverPause: false,
			trimWhiteSpace: true,
			initialSlide: 0,
			keysControl: false,
			touchDrag: false,
			mouseDrag: false
		},
		sliderSettings: {
			dotSize: 20,
			width: '30rem',
			height: 2,
			contained: false,
			direction: 'ltr',
			min: 1,
			interval: 1,
			disabled: false,
			clickable: true,
			duration: 0.5,
			tooltip: 'active',
			tooltipPlacement: 'top',
			useKeyboard: false,
			keydownHook: null,
			dragOnClick: false,
			enableCross: true,
			fixed: false,
			order: true,
			marks: false,
			process: true
		},
		currentSlide: 1,
		isReady: false
	}),
	created() {
		const index = this.$store.getters['data/motion'].findIndex(
			(item) => item.name === this.slug
		)
		if (index > -1) {
			this.item = this.$store.getters['data/motion'][index]
			this.$store.dispatch('settings/updateAreas', this.item.area)
			const currentArea = {
				type: this.item.type,
				name: this.item.name
			}
			this.$store.dispatch('settings/updateCurrentArea', currentArea)
		} else {
			this.$router.replace('/motion')
		}
	},
	mounted() {
		this.sliderSettings.max = this.item.images.length
		this.isReady = true
	},
	methods: {
		changeSlide() {
			const newVal = this.currentSlide - 1
			this.$refs.hooper.slideTo(newVal)
		}
	}
}
</script>
