<template>
	<article class="page__error">
		<img :src="$imgUrl('404.svg')" alt="404" />
		<p>
			{{ $t('errors.404.sub') }}
		</p>
		<router-link tag="a" :to="{ path: '/' }" class="home_button">
			<button class="button__primary u__margin-top-30">
				{{ $t('errors.404.button') }}
			</button>
		</router-link>
	</article>
</template>

<script>
export default {
	created() {
		this.$store.dispatch('settings/clearArea')
	}
}
</script>
