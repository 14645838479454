import axios from 'axios'
import store from '../store'

let axiosConnection = axios.create({
	baseURL: process.env.VUE_APP_BASE_API
})

axiosConnection.interceptors.request.use((config) => {
	config.headers = { authorization: store.getters['auth/authToken'] }
	return config
})

export default axiosConnection
