<template>
	<div class="flags">
		<span
			v-for="lang in languages"
			:key="lang.id"
			@click="changeLocale(lang)"
			@keyup.enter="changeLocale(lang)"
			:ref="lang.flag"
			tabindex="0"
		>
			{{ lang.language.toUpperCase() }}
		</span>
	</div>
</template>

<script>
export default {
	data: () => ({
		languages: [
			{ id: 2, flag: 'pl', language: 'pl' },
			{ id: 1, flag: 'us', language: 'eng' }
		]
	}),
	mounted() {
		let defaultItem = { flag: 'pl', language: 'pl' }
		if (localStorage.getItem('i18n')) {
			this.languages.forEach((item) => {
				if (item.language === localStorage.getItem('i18n')) {
					defaultItem = item
				}
			})
		}
		this.$i18n.locale = defaultItem.language
		this.$refs[defaultItem.flag][0].classList.add('selected')
	},
	methods: {
		changeLocale(lang) {
			localStorage.setItem('i18n', lang.language)
			this.$i18n.locale = lang.language
			Object.values(this.$refs)
				.flat()
				.forEach((item) => {
					item.classList.remove('selected')
				})
			this.$refs[lang.flag][0].classList.add('selected')
		}
	}
}
</script>
