<template>
	<article class="article">
		<div class="max-width photo__article">
			<button class="photo__toggle photo__toggle-first" @click="layout = 'grid'">
				<img :src="$imgUrl('GRID.svg')" alt="photo-GRID.svg" />
			</button>
			<button class="photo__toggle" @click="layout = 'gallery'">
				<img :src="$imgUrl('GALLERY.svg')" alt="GALLERY.svg" />
			</button>
			<transition name="fade" mode="out-in">
				<div v-if="layout === 'grid'" class="photo__container" key="grid">
					<div
						class="photo__item"
						v-for="(image, index) in images"
						:key="items[activeItemIndex].name + index"
						tabindex="0"
						@click="tinyIndex = index"
						@keyup.enter="tinyIndex = index"
					>
						<img :src="$imgUrl(image)" :alt="items[activeItemIndex].name" />
					</div>
				</div>
				<div v-else class="photo__gallery-container" key="gallery">
					<div class="photo__controls">
						<p class="heading__h4">
							{{
								$t(`${items[activeItemIndex].type}.${items[activeItemIndex].name}.title`)
							}}
						</p>
						<button class="photo__toggle" @click="toggleCopy">
							<img :src="$imgUrl('INFO_SEKCJA_WEB.svg')" alt="INFO_SEKCJA_WEB.svg" />
						</button>
					</div>
					<hooper
						:settings="hooperSettings"
						class="photo__gallery"
						ref="hooper"
						@slide="updateActive"
					>
						<slide
							v-for="(image, index) in images"
							:key="items[activeItemIndex].name + index"
							class="photo__slide"
						>
							<div class="photo__slide-image" @click="tinyIndex = index">
								<img :src="$imgUrl(image)" :alt="items[activeItemIndex].name" />
								<div v-if="activeCopy" class="photo__copy-container">
									<div class="photo__copy">
										<p>{{ $t(`${items[activeItemIndex].type}.${items[activeItemIndex].name}.description`) }}</p>
									</div>
								</div>
							</div>
						</slide>
						<hooper-navigation slot="hooper-addons" />
					</hooper>
				</div>
			</transition>
			<Tinybox v-model="tinyIndex" :images="tinyImages" no-thumbs />
		</div>
	</article>
</template>

<script>
import Tinybox from 'vue-tinybox'
import { map, flatMap, findIndex, find } from 'lodash'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'

export default {
	components: {
		Tinybox,
		Hooper,
		Slide,
		HooperNavigation
	},
	data: () => ({
		hooperSettings: {
			centerMode: true,
			infiniteScroll: true,
			wheelControl: false,
			touchDrag: true,
			mouseDrag: false,
			autoPlay: false,
			hoverPause: true,
			trimWhiteSpace: false,
			itemsToShow: 1,
			keysControl: false
		},
		layout: 'grid',
		tinyIndex: null,
		activeCopy: false,
		activeItemIndex: 0
	}),
	created() {
		this.$store.dispatch('settings/clearArea')
	},
	computed: {
		items: ({ $store }) => $store.getters['data/photo'],
		images: ({ items }) => flatMap(items, (item) => [...item.images]),
		tinyImages: ({ images, $imgUrl }) =>
			map(images, (image) => ({
				src: $imgUrl(image)
			})),
		activeItem: ({ layout, items, activeItemIndex }) =>
			layout === 'gallery' ? items[activeItemIndex] : null
	},
	methods: {
		toggleCopy() {
			this.activeCopy = !this.activeCopy
		},
		updateActive({ currentSlide }) {
			this.activeCopy = false
			let imageIndex = currentSlide

			if (currentSlide === -1) {
				imageIndex = this.images.length - 1
			}
			if (currentSlide === this.images.length) {
				imageIndex = 0
			}

			this.activeItemIndex = findIndex(this.items, (item) => {
				return find(item.images, (image) => {
					return image === this.images[imageIndex]
				})
			})
		}
	}
}
</script>
