<template>
	<div id="app">
		<app-header />
		<transition name="fade" mode="out-in">
			<router-view />
		</transition>
		<app-footer />
		<area-switch />
		<description-box />
	</div>
</template>

<script>
import AppHeader from '@/components/app-header'
import AppFooter from '@/components/app-footer'
import AreaSwitch from '@/components/area-switch'
import DescriptionBox from '@/components/description-box'

export default {
	name: 'App',
	components: {
		AppHeader,
		AppFooter,
		AreaSwitch,
		DescriptionBox
	}
}
</script>
<style src="./assets/output.css"></style>
