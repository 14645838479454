<template>
	<article class="article web__article">
		<hooper
			class="web__hooper"
			:settings="hooperSettings"
			:style="{ height: 'auto' }"
			@slide="updateUrl"
			ref="hooper"
		>
			<slide
				:class="['web__slide', { 'web__slide-mobile': mobileClass }]"
				v-for="item in items"
				:key="item.id"
			>
				<web-gallery
					:item="item"
					:is-mobile="isMobile"
					:mobile-class="mobileClass"
					@responsive="toggleResponsive"
					ref="gallery"
				/>
			</slide>
			<hooper-navigation slot="hooper-addons" />
		</hooper>
		<div v-if="!isReady" class="loader" />
	</article>
</template>

<script>
import WebGallery from '@/components/web-gallery'
import { isNull, forEach } from 'lodash'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'

export default {
	components: { WebGallery, Hooper, Slide, HooperNavigation },
	data: ({ $route }) => ({
		hooperSettings: {
			centerMode: true,
			infiniteScroll: true,
			wheelControl: false,
			touchDrag: false,
			mouseDrag: false,
			autoPlay: false,
			hoverPause: true,
			trimWhiteSpace: false,
			itemsToShow: 1,
			initialSlide: 0
		},
		slug: $route.params.web,
		isReady: false,
		isMobile: false,
		width: 1000
	}),
	computed: {
		items: ({ $store }) => $store.getters['data/web'],
		mobileClass: ({ width, isMobile }) => width >= 900 && isMobile
	},
	created() {
		const index = this.items.findIndex((item) => item.name === this.slug)
		if (index > -1) {
			this.hooperSettings.initialSlide = index
			this.$store.dispatch('settings/updateAreas', this.items[index].area)
			const currentArea = {
				type: this.items[index].type,
				name: this.items[index].name
			}
			this.$store.dispatch('settings/updateCurrentArea', currentArea)
		} else {
			this.$router.replace('/web')
		}
	},
	mounted() {
		window.addEventListener('resize', this.recalculate)

		// update all galleries
		setTimeout(() => {
			this.recalculate()
			forEach(this.$refs.gallery, (item) => {
				item.$refs.hooper.updateWidth()
			})
		}, 500)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.recalculate)
	},
	methods: {
		updateUrl({ currentSlide, slideFrom }) {
			if (currentSlide !== slideFrom && !isNull(slideFrom)) {
				// special case for hooper on infinite scroll
				let index = currentSlide

				if (currentSlide === -1) {
					index = this.items.length - 1
				}
				if (currentSlide === this.items.length) {
					index = 0
				}
				this.$router.replace(`/web/${this.items[index].name}`)
				this.$store.dispatch('settings/updateAreas', this.items[index].area)
				const currentArea = {
					type: this.items[index].type,
					name: this.items[index].name
				}
				this.$store.dispatch('settings/updateCurrentArea', currentArea)

				// update all galleries after slide
				forEach(this.$refs.gallery, (item) => {
					item.$refs.hooper.updateWidth()
				})
			}
		},
		toggleResponsive(bool) {
			this.isReady = false
			this.width = window.innerWidth
			this.isMobile = bool

			setTimeout(() => {
				this.recalculate()
			}, 500)
		},
		recalculate() {
			this.$refs.hooper.updateWidth()
			this.isReady = true
		}
	}
}
</script>
