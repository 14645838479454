<template>
	<div
		v-if="pageDescription"
		:class="['description-box', { 'description-box--hidden': isHidden }]"
		@click="isHidden = !isHidden"
	>
		<transition name="fade" mode="out-in">
			<div class="description-box__container" :key="$route.name">
				<h4 class="description-box__title" v-html="$t(`${$route.name}.title`)" />
				<div class="description-box__text">
					<p
						v-for="text in descriptionTexts"
						:key="text"
						v-html="$t(`${$route.name}.description.${text}`)"
					/>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { reverse } from 'lodash'

export default {
	data: () => ({
		isHidden: true
	}),
	computed: {
		pageDescription: ({ $store }) => $store.getters['settings/pageDescription'],
		descriptionTexts: ({ pageDescription }) => {
			const array = []

			for (let i = pageDescription; i > 0; i--) {
				array.push(i)
			}

			return reverse(array)
		}
	}
}
</script>
