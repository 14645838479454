import Vue from 'vue'
import store from '../store'
import axios from './axios'

import Functions from './functions'
import Directives from './directives'

Vue.prototype.$axios = axios
Vue.prototype.$console = 'color:coral'

Vue.prototype.$apiImgUrl = (pic) => process.env.VUE_APP_BASE_API_IMG + pic

Vue.prototype.$imgUrl = Functions.imgUrl
Vue.prototype.$compareValues = Functions.compareValues
Vue.prototype.$asyncForEach = Functions.asyncForEach
Vue.prototype.$shuffle = Functions.shuffle
Vue.prototype.$randomInt = Functions.randomInt

Vue.prototype.$alertConfirm = (obj) => store.dispatch('alert/confirm', obj)
Vue.prototype.$alertBox = (obj) => store.dispatch('alert/box', obj)

Vue.directive('click-outside', Directives.click_outside)

Vue.filter('kb', (val) => Math.floor(val / 1024))
