<template>
	<div class="web__gallery">
		<div class="web__gallery-label">
			<button
				:class="[
					'web__gallery-toggle',
					'web__gallery-toggle-responsive',
					{ 'web__gallery-toggle-responsive__active': isMobile }
				]"
				@click="toggleResponsive"
			>
				<img :src="$imgUrl('WEB_RESPONSIVE.svg')" alt="WEB_RESPONSIVE.svg" />
			</button>
			<a
				class="heading__h4"
				:href="$t(`${item.type}.${item.name}.link`)"
				target="_blank"
			>
				{{ $t(`${item.type}.${item.name}.title`) }}
			</a>
			<button
				class="web__gallery-toggle web__gallery-toggle-copy"
				@click="activeCopy = !activeCopy"
			>
				<img :src="$imgUrl('INFO_SEKCJA_WEB.svg')" alt="INFO_SEKCJA_WEB.svg" />
			</button>
		</div>
		<hooper
			class="web__gallery-hooper"
			:settings="hooperSettings"
			:style="{ height: 'auto', opacity: isReady ? '1' : '0' }"
			@beforeSlide="activeCopy = false"
			@afterSlide="recalculate"
			ref="hooper"
		>
			<template v-if="isMobile">
				<slide
					:class="[
						'web__gallery-slide',
						{ 'web__gallery-slide__background': activeCopy }
					]"
					v-for="(img, index) in mobileImages"
					:key="index"
				>
					<div class="web__image">
						<img :src="$imgUrl(img)" :alt="img" />
					</div>
					<div v-if="activeCopy" class="web__copy">
						<div class="web__copy-wrap">
							<p>{{ $t(`${item.type}.${item.name}.text`) }}</p>
						</div>
					</div>
				</slide>
			</template>
			<template v-else>
				<slide
					:class="[
						'web__gallery-slide',
						{ 'web__gallery-slide__background': activeCopy }
					]"
					v-for="(img, index) in desktopImages"
					:key="index"
				>
					<div class="web__image">
						<img :src="$imgUrl(img)" :alt="img" />
					</div>
					<div v-if="activeCopy" class="web__copy">
						<div class="web__copy-wrap">
							<p>{{ $t(`${item.type}.${item.name}.text`) }}</p>
						</div>
					</div>
				</slide>
			</template>
			<hooper-pagination slot="hooper-addons" />
		</hooper>
	</div>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper'

export default {
	props: {
		item: {
			type: Object,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		},
		mobileClass: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			mobileImages: this.item.images.mobile,
			desktopImages: this.item.images.desktop,
			hooperSettings: {
				centerMode: false,
				infiniteScroll: true,
				wheelControl: false,
				touchDrag: true,
				mouseDrag: true,
				autoPlay: false,
				hoverPause: true,
				trimWhiteSpace: true
			},
			activeCopy: false,
			isReady: false
		}
	},
	watch: {
		isMobile() {
			this.isReady = false
			setTimeout(() => {
				this.recalculate()
				this.isReady = true
			}, 500)
		}
	},
	methods: {
		toggleResponsive() {
			this.activeCopy = false
			this.$emit('responsive', !this.isMobile)
			this.isReady = false

			setTimeout(() => {
				this.recalculate()
				this.isReady = true
			}, 500)
		},
		recalculate() {
			this.$refs.hooper.updateWidth()
		}
	},
	mounted() {
		this.isReady = false
		// hooper inside hooper is not loading correctly
		const hackTimer = setInterval(() => {
			if (
				document.querySelector('.web__gallery-slide') &&
				document.querySelector('.web__gallery-slide').style.width !== '0px'
			) {
				clearInterval(hackTimer)
				this.isReady = true
			}
			this.recalculate()
		}, 100)

		window.addEventListener('resize', this.recalculate)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.recalculate)
	},
	components: {
		Hooper,
		Slide,
		HooperPagination
	}
}
</script>
