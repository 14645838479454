import data from './data'
import { STATIC, WEB, MOTION, PHOTO } from '@/helpers/constants'
import { filter } from 'lodash'

export default {
	namespaced: true,
	state: {
		data
	},
	getters: {
		data: (state) => state.data,
		static: (state) => filter(state.data, { type: STATIC }),
		web: (state) => filter(state.data, { type: WEB }),
		motion: (state) => filter(state.data, { type: MOTION }),
		photo: (state) => filter(state.data, { type: PHOTO })
	},
	mutations: {},
	actions: {}
}
