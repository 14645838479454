<template>
	<article class="article web__article" />
</template>

<script>
export default {
	computed: {
		items: ({ $store }) => $store.getters['data/web']
	},
	created() {
		this.$store.dispatch('settings/clearArea')
		this.$router.replace({ path: `/web/${this.items[0].name}` })
	}
}
</script>
