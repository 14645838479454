<template>
	<div class="area-switch">
		<router-link
			:class="['button__area', { active: area === currentArea.type }]"
			v-for="(area, i) in areas"
			:key="i"
			:to="{ path: `/${area}/${currentArea.name}` }"
		>
			{{ area }}
		</router-link>
	</div>
</template>

<script>
export default {
	computed: {
		areas: ({ $store }) => $store.getters['settings/areas'],
		currentArea: ({ $store }) => $store.getters['settings/currentArea']
	}
}
</script>
