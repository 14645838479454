<template>
	<article class="article contact">
		<div class="contact__email">
			<a :href="`mailto:${$t('external.email')}`" class="button__primary">
				{{ $t('external.email') }}
			</a>
		</div>
		<section class="contact__wrap">
			<div v-for="{ id, name, photo } in people" :key="id" class="contact__item">
				<div class="contact__item-wrap">
					<img class="contact__image" :src="$imgUrl(photo)" :alt="name" />
				</div>
			</div>
		</section>
	</article>
</template>

<script>
export default {
	data: () => ({
		people: [
			{
				id: 0,
				name: 'joanna-wiernicka',
				photo: 'ASIA-vertical.jpg'
			},
			{
				id: 1,
				name: 'marcin-chlewicki',
				photo: 'MARCIN-vertical.jpg'
			},
			{
				id: 2,
				name: 'jedrzej-guzik',
				photo: 'JE-vertical.jpg'
			}
		]
	}),
	mounted() {
		this.$store.dispatch('settings/clearArea')
		document.querySelector('body').classList.add('body__black')
	},
	beforeDestroy() {
		document.querySelector('body').classList.remove('body__black')
	}
}
</script>
