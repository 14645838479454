<template>
	<article class="article static__article">
		<div class="static__article-wrap">
			<static-gallery :items="item.images" slide-class="static__slide" />
			<static-description :item="item" />
		</div>
		<div class="static__mobile">
			<static-gallery
				class="static__mobile-gallery"
				:items="item.images"
				slide-class="static__slide-mobile"
			/>
			<h2 class="static__mobile-heading">
				{{ $t(`${item.type}.${item.name}.title`) }}
			</h2>
			<button @click="showDesc = !showDesc">
				<transition name="fade" mode="out-in">
					<img :src="$imgUrl(btnImg)" alt="btn" :key="btnImg" />
				</transition>
			</button>
			<transition name="fade" mode="out-in">
				<static-description v-if="showDesc" :item="item" />
			</transition>
		</div>
	</article>
</template>

<script>
import StaticGallery from '@/components/static-gallery'
import StaticDescription from '@/components/static-description'

export default {
	components: {
		StaticGallery,
		StaticDescription
	},
	data: ({ $route }) => ({
		slug: $route.params.static,
		item: {},
		showDesc: false
	}),
	computed: {
		btnImg: ({ showDesc }) => (showDesc ? 'TOGGLE-X.svg' : 'TOGGLE-TXT.svg')
	},
	created() {
		const index = this.$store.getters['data/static'].findIndex(
			(item) => item.name === this.slug
		)
		if (index > -1) {
			this.item = this.$store.getters['data/static'][index]
			this.$store.dispatch('settings/updateAreas', this.item.area)
			const currentArea = {
				type: this.item.type,
				name: this.item.name
			}
			this.$store.dispatch('settings/updateCurrentArea', currentArea)
		} else {
			this.$router.replace('/static')
		}
	}
}
</script>
