import Home from '../pages/home'
import Motion from '../pages/motion'
import Photo from '../pages/photo'
import Web from '../pages/web'
import Static from '../pages/static'
import Contact from '../pages/contact'

import StaticItem from '../pages/static-item'
import WebItem from '../pages/web-item'
import MotionItem from '../pages/motion-item'

import PageNotFound from '../pages/404'
import RouterHelper from '../helpers/router-helper'

export const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/motion',
		component: RouterHelper,
		children: [
			{
				path: '',
				name: 'motion',
				component: Motion,
				meta: {
					description: 3
				}
			},
			{
				path: ':motion',
				name: 'motion-item',
				component: MotionItem
			}
		]
	},
	{
		path: '/web',
		component: RouterHelper,
		children: [
			{
				path: '',
				name: 'web-redirect',
				component: Web
			},
			{
				path: ':web',
				name: 'web',
				component: WebItem,
				meta: {
					description: 3
				}
			}
		]
	},
	{
		path: '/static',
		component: RouterHelper,
		children: [
			{
				path: '',
				name: 'static',
				component: Static,
				meta: {
					description: 3
				}
			},
			{
				path: ':static',
				name: 'static-item',
				component: StaticItem
			}
		]
	},
	{
		path: '/photo',
		name: 'photo',
		component: Photo,
		meta: {
			description: 3
		}
	},
	{
		path: '/contact',
		name: 'contact',
		component: Contact
	},
	{
		path: '*',
		name: 'PageNotFound',
		component: PageNotFound
	}
]
