<template>
	<article class="article">
		<div class="static__mozaic">
			<router-link
				v-for="item in items"
				:key="item.id"
				class="static__mozaic-item"
				:to="{ path: `/static/${item.name}` }"
			>
				<img :src="$imgUrl(item.images[0])" :alt="item.name" />
			</router-link>
		</div>
	</article>
</template>

<script>
export default {
	mounted() {
		this.$store.dispatch('settings/clearArea')
		document.querySelector('body').classList.add('static__pattern')
	},
	beforeDestroy() {
		document.querySelector('body').classList.remove('static__pattern')
	},
	computed: {
		items: ({ $store }) => $store.getters['data/static']
	}
}
</script>
