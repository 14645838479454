<template>
	<article class="article">
		<div class="home__content">
			<img
				:src="$imgUrl('cud-home-typing.svg')"
				alt="art1"
				class="home__content-art"
			/>
		</div>
	</article>
</template>

<script>
export default {
	created() {
		this.$store.dispatch('settings/clearArea')
	}
}
</script>
