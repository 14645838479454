var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"web__gallery"},[_c('div',{staticClass:"web__gallery-label"},[_c('button',{class:[
				'web__gallery-toggle',
				'web__gallery-toggle-responsive',
				{ 'web__gallery-toggle-responsive__active': _vm.isMobile }
			],on:{"click":_vm.toggleResponsive}},[_c('img',{attrs:{"src":_vm.$imgUrl('WEB_RESPONSIVE.svg'),"alt":"WEB_RESPONSIVE.svg"}})]),_c('a',{staticClass:"heading__h4",attrs:{"href":_vm.$t(((_vm.item.type) + "." + (_vm.item.name) + ".link")),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.item.type) + "." + (_vm.item.name) + ".title")))+" ")]),_c('button',{staticClass:"web__gallery-toggle web__gallery-toggle-copy",on:{"click":function($event){_vm.activeCopy = !_vm.activeCopy}}},[_c('img',{attrs:{"src":_vm.$imgUrl('INFO_SEKCJA_WEB.svg'),"alt":"INFO_SEKCJA_WEB.svg"}})])]),_c('hooper',{ref:"hooper",staticClass:"web__gallery-hooper",style:({ height: 'auto', opacity: _vm.isReady ? '1' : '0' }),attrs:{"settings":_vm.hooperSettings},on:{"beforeSlide":function($event){_vm.activeCopy = false},"afterSlide":_vm.recalculate}},[(_vm.isMobile)?_vm._l((_vm.mobileImages),function(img,index){return _c('slide',{key:index,class:[
					'web__gallery-slide',
					{ 'web__gallery-slide__background': _vm.activeCopy }
				]},[_c('div',{staticClass:"web__image"},[_c('img',{attrs:{"src":_vm.$imgUrl(img),"alt":img}})]),(_vm.activeCopy)?_c('div',{staticClass:"web__copy"},[_c('div',{staticClass:"web__copy-wrap"},[_c('p',[_vm._v(_vm._s(_vm.$t(((_vm.item.type) + "." + (_vm.item.name) + ".text"))))])])]):_vm._e()])}):_vm._l((_vm.desktopImages),function(img,index){return _c('slide',{key:index,class:[
					'web__gallery-slide',
					{ 'web__gallery-slide__background': _vm.activeCopy }
				]},[_c('div',{staticClass:"web__image"},[_c('img',{attrs:{"src":_vm.$imgUrl(img),"alt":img}})]),(_vm.activeCopy)?_c('div',{staticClass:"web__copy"},[_c('div',{staticClass:"web__copy-wrap"},[_c('p',[_vm._v(_vm._s(_vm.$t(((_vm.item.type) + "." + (_vm.item.name) + ".text"))))])])]):_vm._e()])}),_c('hooper-pagination',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }