<template>
	<article class="article">
		<div class="motion-wrap">
			<router-link
				v-for="(item, idx) in elements"
				:key="item.id"
				class="motion-wrap__item"
				:to="{ path: `/motion/${item.name}` }"
			>
				<video
					preload="true"
					autoplay
					muted
					loop
					playsinline
					class="video"
					@mouseenter="pauseVideos(idx)"
					@mouseleave="playVideos"
				>
					<source :src="$imgUrl(item.image)" type="video/mp4" />
					Your browser does not support HTML5 video.
				</video>
			</router-link>
		</div>
	</article>
</template>

<script>
import { reduce, forEach, shuffle } from 'lodash'

export default {
	created() {
		this.$store.dispatch('settings/clearArea')
	},
	computed: {
		items: ({ $store }) => $store.getters['data/motion'],
		elements: ({ items }) =>
			shuffle(
				reduce(
					items,
					(acc, curr) => {
						// bo nie są reprezentatywne XD
						if (curr.name !== 'cosmos-elements') {
							forEach(curr.images, (image) => {
								acc.push({
									name: curr.name,
									image
								})
							})
						}
						return acc
					},
					[]
				)
			)
	},
	methods: {
		pauseVideos(idx) {
			const videos = document.querySelectorAll('.video')
			for (let i = 0; i < videos.length; i++) {
				if (i !== idx) {
					videos[i].pause()
				}
			}
		},
		playVideos() {
			const videos = document.querySelectorAll('.video')
			for (let i = 0; i < videos.length; i++) {
				videos[i].play()
			}
		}
	}
}
</script>
